import { createStore } from 'vuex'
import router from '../router'
import { toDateString } from 'vue'

export default createStore({
  state: {
    reclamos: [],
    reclamo: {
      id: '',
      tipo:'',
      usuario: '',
      fecha: null,
      descripcion: '',
      estado:'',
      respuestaV:'',
      respuestaE:'',
      check: false,
      bandeja:'',
      evidencia:null,
      categoria: '',
      subCategoria: ''
    },
    logs: [],
    log:{
      timestamp: '',
      usuario: '',
      mensaje: '',
      estado: ''
    },
    usuarios: [],
    usuario: {
      rut:0,
      dv:'0',
      nombre:'',
      apellido:'',
      email: '',
      wsp:null,
      proy: {},
      tipo:''
    },
    usuarios2: [],
    proyectos: [],
    logs: [],
    user: null,
    msg:''
  },
  mutations: {
    setUser(state, payload){
      state.user = payload
    },
    cargar (state, payload){
      state.reclamos = payload
    },
    cargarUser (state, payload){
      state.usuario = payload
    },
    cargarUsuarios (state, payload){
      state.usuarios2 = payload
    },
    cargarProyectos (state, payload){
      state.proyectos = payload
    },
    cargarLogs (state, payload){
      state.logs = payload
    },
    set(state, payload){
      state.reclamos.push(payload)
    },
    setLog(state, payload){
      state.logs.push(payload)
    },
    setUsuarios(state, payload){
      state.usuarios2.push(payload)
    },
    eliminar(state, payload){
      state.reclamos=state.reclamos.filter(item => item.id != payload)
      
    },
    reclamo(state, payload){
    //  if (!state.reclamos.find(item=> item.id === payload)){
    //   router.push('/')
    //    return
    //  }
      state.reclamo = state.reclamos.find(item=> item.id === parseFloat(payload))
    //router.push('/')
    },
    update(state, payload){
      state.reclamos = state.reclamos.map(item=> item.id === payload.id ? payload : item)
      
    },
    update2(state, payload){
      state.reclamos = state.reclamos.map(item=> item.id === payload.id ? payload : item)
      router.push('/')
      
    }

  },
  actions: {
    cerrarSesion({commit}){
      commit('setUser', null)
      router.push('/ingreso')
      localStorage.removeItem('user')
    },
    async ingresoUser({commit}, user){
      try {
        const res = await fetch('https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyCQ_tL19Udw1wpLebetJKXqNtdsmkcGGsc',{
          method: 'POST',
          body: JSON.stringify({
            email: user.email,
            password: user.password,
            returnSecureToken: true
          })
        })
        const userDB = await res.json()
        if (userDB.error){
          commit ('setUser', userDB.error.message)
          return
        }
        commit ('setUser', userDB)
        router.push('/homeview')
        localStorage.setItem('user', JSON.stringify(userDB))
      } catch (error) {
        console.log(error)
      }
    },
    async registrarUser({commit}, user){
      try {
        const res = await fetch('https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyCQ_tL19Udw1wpLebetJKXqNtdsmkcGGsc',{
          method: 'POST',
          body: JSON.stringify({
            email: user.email,
            password: user.password,
            returnSecureToken: true
          })
        })
        const userDB = await res.json()
        if (userDB.error){
          console.log(userDB.error.message)
          return
        }
        commit ('setUser', userDB)
        router.push('/usuario')
        localStorage.setItem('user', JSON.stringify(userDB))
      } catch (error) {
        console.log(error)
      }
    },
    async setUsuarios({commit, state}, usuario){
      usuario.email = state.user.email
      //usuario.fecha = Date(Date.now())
      try {
        const res = await fetch (`https://mvp-relacionamiento-default-rtdb.firebaseio.com/usuarios/${state.user.localId}/.json?auth=${state.user.idToken}`, {
         method: 'PUT',
         headers: {
          'Content-Type': 'application/json'
         },
         body: JSON.stringify(usuario)
        })
        
        const dataDB = await res.json
        console.log(dataDB)

      } catch (error) {
        console.log(error)
      }
      commit('setUsuarios', usuario)
      router.push('/')
      },
     async cargarReclamos({ commit, state}) {
      if (localStorage.getItem('user')){
        commit ('setUser', JSON.parse(localStorage.getItem('user')))
      } else {
        return commit ('setUser', null)
      }
      try {
        const res = await fetch(`https://mvp-relacionamiento-default-rtdb.firebaseio.com/reclamos/.json?auth=${state.user.idToken}`)
        const dataDB = await res.json()
        const arrayReclamos = []

        for(let id in dataDB){
          arrayReclamos.push(dataDB[id])
        }

        commit('cargar', arrayReclamos)

      } catch (error) {
        console.error(error)
      }
    },
    async cargarReclamos2({ commit, state}) {
      if (localStorage.getItem('user')){
        commit ('setUser', JSON.parse(localStorage.getItem('user')))
      } else {
        return commit ('setUser', null)
      }
      //console.log(state.user.idToken)
      try {
        const res = await fetch(`https://mvp-relacionamiento-default-rtdb.firebaseio.com/reclamos/.json?auth=${state.user.idToken}`)
        const dataDB = await res.json()
        const arrayUsuarios = []

        for(let id in dataDB){
          arrayUsuarios.push(dataDB[id])
        }
        commit('cargarUsuarios', arrayUsuarios)

      } catch (error) {
        console.error(error)
      }
    },
    async cargarLogs({ commit, state}) {
      if (localStorage.getItem('user')){
        commit ('setUser', JSON.parse(localStorage.getItem('user')))
      } else {
        return commit ('setUser', null)
      }
      //console.log(state.user.idToken)
      try {
        const res = await fetch(`https://mvp-relacionamiento-default-rtdb.firebaseio.com/log/.json?auth=${state.user.idToken}`)
        const dataDB = await res.json()
        const arrayLogs = []

        for(let id in dataDB){
          arrayLogs.push(dataDB[id])
        }
        commit('cargarLogs', arrayLogs)

      } catch (error) {
        console.error(error)
      }
    },
    async cargarUsuario({ commit, state}) {
      if (localStorage.getItem('user')){
        commit ('setUser', JSON.parse(localStorage.getItem('user')))
      } else {
        return commit ('setUser', null)
      }
      //console.log(state.user.idToken)
      try {
        const res = await fetch(`https://mvp-relacionamiento-default-rtdb.firebaseio.com/usuarios/.json?auth=${state.user.idToken}`)
        const dataDB = await res.json()

        for(let id in dataDB){
          if (dataDB[id].email === JSON.parse(localStorage.getItem('user')).email) {
            commit('cargarUser', dataDB[id])
          }
        }
      } catch (error) {
        console.error(error)
      }
    },
    async cargarProyectos({ commit, state}) {
      if (localStorage.getItem('user')){
        commit ('setUser', JSON.parse(localStorage.getItem('user')))
      } else {
        return commit ('setUser', null)
      }
      try {
        const res = await fetch(`https://mvp-relacionamiento-default-rtdb.firebaseio.com/proyectos/.json?auth=${state.user.idToken}`)
        const dataDB = await res.json()
        const arrayProyectos = []

        for(let id in dataDB){
          arrayProyectos.push(dataDB[id])
        }
        commit('cargarProyectos', arrayProyectos)

      } catch (error) {
        console.error(error)
      }
    },
    async setReclamos({commit, state}, reclamo){
      reclamo.usuario = state.user.email
      try {
        const res = await fetch (`https://mvp-relacionamiento-default-rtdb.firebaseio.com/reclamos/${reclamo.id}.json?auth=${state.user.idToken}`, {
         method: 'PUT',
         headers: {
          'Content-Type': 'application/json'
         },
         body: JSON.stringify(reclamo)
        })
        
        const dataDB = await res.json
        //console.log(dataDB)

      } catch (error) {
        console.log(error)
      }
      commit('set', reclamo) 
      },
    //async deleteReclamo({commit, state}, id){
    //  try {
    //    await fetch (`https://mvp-relacionamiento-default-rtdb.firebaseio.com/usuarios/${state.user.localId}/${id}.json?auth=${state.user.idToken}`, {
    //      method: 'DELETE'
    //    }
    //    )
    //    commit('eliminar', id)
    //  } catch (error) {
    //    console.log(error)        
    //  }
    //},
    editReclamo({commit}, id){
      console.log(id)
      commit('reclamo', id)
    },
    async setLog({commit, state}, log){
      try {
        const res = await fetch (`https://mvp-relacionamiento-default-rtdb.firebaseio.com/log/${log.timestamp}.json?auth=${state.user.idToken}`, {
         method: 'PUT',
         headers: {
          'Content-Type': 'application/json'
         },
         body: JSON.stringify(log)
        })
        
        const dataDB = await res.json
        //console.log(dataDB)

      } catch (error) {
        console.log(error)
      }
      commit('setLog', log) 
      },
    async updateReclamo({commit, state}, reclamo){
      try {
        const res = await fetch (`https://mvp-relacionamiento-default-rtdb.firebaseio.com/reclamos/${reclamo.id}.json?auth=${state.user.idToken}`, {
          method: 'PATCH',
          body: JSON.stringify(reclamo)
        })
        const dataDB = await res.json()
        commit('update', dataDB)
      } catch (error) {
        console.log(error)
      }
    },
    async updateReclamo2({commit, state}, reclamo){
      try {
        const res = await fetch (`https://mvp-relacionamiento-default-rtdb.firebaseio.com/reclamos/${reclamo.id}.json?auth=${state.user.idToken}`, {
          method: 'PATCH',
          body: JSON.stringify(reclamo)
        })
        const dataDB = await res.json()
        commit('update2', dataDB)
      } catch (error) {
        console.log(error)
      }
    },
  },
  getters: {
    userAutenticado(state) {
      return !!state.user
    },
    usuarioTipo(state) {
      return state.usuario
    }
  },
  modules: {
  }
})
