<template>
  <div id="app">
      <Navbar />
    <div class="container" style="padding-top: 70px">
      <router-view/>
    </div>
    <div class="card-footer">
      <Footer />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Footer from './components/Footer.vue'
import Navbar from './components/Navbar.vue'


export default {
  components: {
    Navbar,
    Footer
  },
  methods: {
    ...mapActions(['cargarReclamos'])
  },
  created(){
    this.cargarReclamos()
  }
}
</script>