<template>
  <div class="video-wrapper">
    <div class="video-container">
      <iframe
        ref="youtubeIframe"
        :src="videoUrl"
        frameborder="0"
        allowfullscreen
      ></iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: 'YouTubeShort',
  props: {
    videoId: {
      type: String,
      required: true
    }
  },
  computed: {
    videoUrl() {
      return `https://www.youtube.com/embed/${this.videoId}`;
    }
  },
  mounted() {
    this.loadYouTubeAPI();
  },
  methods: {
    loadYouTubeAPI() {
      if (!window.YT) {
        const tag = document.createElement('script');
        tag.src = "https://www.youtube.com/iframe_api";
        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        window.onYouTubeIframeAPIReady = this.createPlayer;
      } else {
        this.createPlayer();
      }
    },
    createPlayer() {
      this.player = new YT.Player(this.$refs.youtubeIframe, {
        videoId: this.videoId,
        events: {
          'onReady': this.onPlayerReady,
          'onStateChange': this.onPlayerStateChange
        }
      });
    },
    onPlayerReady(event) {
      event.target.playVideo();
    },
    onPlayerStateChange(event) {
      // Maneja los cambios de estado del video aquí
    }
  }
};
</script>

<style scoped>
.video-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.video-container {
  position: relative;
  padding-bottom: 125%; /* Relación de aspecto 4:5 */
  height: 0;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  background: #000;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

/* Ajustes responsivos para pantallas más grandes */
@media (min-width: 768px) {
  .video-container {
    max-width: 400px; /* Ancho máximo del video en pantallas grandes */
    height: calc(400px * 1.25); /* Altura proporcional */
    padding-bottom: 0; /* Elimina el padding-bottom para usar altura fija */
  }
}
</style>
