<template>
  <hr class="border border-dark border-1 opacity-50">
  <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div class="carousel-inner">
    <div class="carousel-item active" data-bs-interval="6000">
      <img src="../assets/slider-1.webp" class="d-block w-100" alt="...">
      <div class="container-fluid">
      <div class="carousel-caption d-none d-md-block">
        <a href="https://api.whatsapp.com/send/?phone=56942721795&text&type=phone_number&app_absent=0" target="_blank"   class="btn btn-dark btn-lg" role="button" ><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-whatsapp" viewBox="0 0 16 16">
          <path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232"/>
        </svg> Habla con un/una experto/a</a>
      </div>
      </div>
    </div>
    <div class="carousel-item" data-bs-interval="6000">
      <img src="../assets/slider-2.webp" class="d-block w-100" alt="...">
      <div class="carousel-caption d-none d-md-block">
        <a href="https://api.whatsapp.com/send/?phone=56942721795&text&type=phone_number&app_absent=0" target="_blank"   class="btn btn-dark btn-lg" role="button" ><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-whatsapp" viewBox="0 0 16 16">
          <path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232"/>
        </svg> Habla con un/una experto/a</a>
      </div>
    </div>
    <div class="carousel-item">
      <img src="../assets/slider-3.webp" class="d-block w-100" alt="...">
      <div class="carousel-caption d-none d-md-block">
        <a href="https://api.whatsapp.com/send/?phone=56942721795&text&type=phone_number&app_absent=0" target="_blank"   class="btn btn-dark btn-lg" role="button" ><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-whatsapp" viewBox="0 0 16 16">
          <path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232"/>
        </svg> Habla con un/una experto/a</a>
      </div>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
  <a name="hacemos"></a>
  <hr class="border border-dark border-1 opacity-50">
  <div class="container text-center">
    <h1>Monitorea eficientemente tus compromisos socioambientales</h1>
  </div>
  <hr class="border border-dark border-1 opacity-50">
  
<div class="text-center">
   <div id="app">
     <YouTubePlayer videoId="esW0bbXGoNA" />
   </div>
</div>

  <hr class="border border-dark border-1 opacity-50">
  <div class="container text-center">
    <a href="https://api.whatsapp.com/send/?phone=56942721795&text&type=phone_number&app_absent=0" target="_blank"   class="btn btn-outline-dark btn-lg" role="button" ><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-whatsapp" viewBox="0 0 16 16">
  <path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232"/>
</svg> Habla con un/una experto/a</a>
  </div>
  <a name="somos"></a>
  <hr class="border border-dark border-1 opacity-50">
  <h2>Quienes somos</h2>
  <p>En MRP estamos comprometidos con la construcción de relaciones virtuosas entre empresas y comunidades a través de soluciones innovadoras en el relacionamiento comunitario. Creemos en el diálogo como una herramienta que da sostenibilidad a la actividad de las empresas en el territorio. Nuestro equipo está conformado por profesionales altamente capacitados y comprometidos con la construcción de relaciones sostenibles y beneficiosas para todas las partes involucradas.</p>
  <hr class="border border-dark border-1 opacity-50">
    <h2>Conoce el equipo detrás de MRP</h2>
    <P>Nuestros socios están comprometidos con la mejora de las relaciones comunitarias y tienen una amplia experiencia en consultoría estratégica en sustentabilidad.</P>
  
    <div class="container-fluid">
  <div class="row justify-content-center text-center">
    <div class="col-12 col-md-4 mb-4">
      <div class="card" style="width: 18rem;">
        <img src="../assets/seba.webp" class="card-img-top" alt="Seba Barrios">
        <div class="card-body">
          <h5 class="card-title">Sebastián Barrios Julian</h5>
          <p class="card-text">Ingeniero Civil Mecánico de la Universidad de Chile, con vasta experiencia en el ámbito de la sustentabilidad y el relacionamiento comunitario, en el sector público, privado y academia. Es fundador de Rubik y actual CEO en MRP.</p>
          <a href="https://www.linkedin.com/in/sebastian-ignacio-barrios-jullian-57621110a/" target="_blank" class="btn btn-secondary">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-linkedin" viewBox="0 0 16 16">
              <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z"/>
            </svg>
            Más info
          </a>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-4 mb-4">
      <div class="card" style="width: 18rem;">
        <img src="../assets/monica.webp" class="card-img-top" alt="Monica Morales">
        <div class="card-body">
          <h5 class="card-title">Mónica Morales Labbé</h5>
          <p class="card-text">Trabajadora social de la Pontificia Universidad Católica de Chile, con experiencia en fundaciones, academia y consultoría en participación ciudadana y relacionamiento comunitario. Es Jefa de Proyectos en Rubik Sustentabilidad y CCO en MRP.</p>
          <a href="https://www.linkedin.com/in/monica-morales-b96aa8ba/" target="_blank" class="btn btn-secondary">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-linkedin" viewBox="0 0 16 16">
              <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z"/>
            </svg>
            Más info
          </a>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-4 mb-4">
      <div class="card" style="width: 18rem;">
        <img src="../assets/pepe.webp" class="card-img-top" alt="Pepe Coloma">
        <div class="card-body">
          <h5 class="card-title">José Coloma Canales</h5>
          <p class="card-text">Ingeniero en Informática y MBA de la Universidad Adolfo Ibáñez, con experiencia en docencia universitaria y desarrollo tecnológico en el sector público y privado. Es el encargado de Marketing en la Universidad de La Frontera, CTO y CMO en MRP.</p>
          <a href="https://www.linkedin.com/in/coloma/" target="_blank" class="btn btn-secondary">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-linkedin" viewBox="0 0 16 16">
              <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z"/>
            </svg>
            Más info
          </a>
        </div>
      </div>
    </div>
  </div>
</div>


  <hr class="border border-dark border-1 opacity-50">
  <h2>Alianza con Rubik Sustentabilidad</h2>
  <div class="container-fluid">
  <div class="row justify-content-evenly">
    <div class="col col-lg-2">
      <div class="card" style="width: 10rem;" >
      <a href="https://www.rubiksustentabilidad.cl/" target="_blank"> 
          <img src="../assets/logo-rubik.jpg" class="card-img-top" alt="Rubik Sustentabilidad"> 
        </a>
      </div>
    </div>
    <div class="col">
      <p>MRP nace al alero de Rubik, una empresa de consultoría estratégica en sustentabilidad con una sólida trayectoria en el campo del relacionamiento comunitario. Esta alianza estratégica nos permite combinar nuestro desarrollo tecnologico con el conocimiento profundo de Rubik en materia de sustentabilidad y relaciones comunitarias, ofreciendo soluciones integrales y efectivas para nuestros clientes.</p>
    </div>
   </div> 
   </div>
   <hr class="border border-dark border-1 opacity-50">
   <div class="container text-center">
    <a href="https://api.whatsapp.com/send/?phone=56982683599&text&type=phone_number&app_absent=0" target="_blank" class="btn btn-outline-dark btn-lg" role="button"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-whatsapp" viewBox="0 0 16 16">
  <path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232"/>
</svg> Habla con un/una experto/a</a>
  </div>
  <!--</div>-->

</template>
<script>
  import YouTubePlayer from '../components/YouTubePlayer.vue';

  export default {
  name: 'App',
  components: {
    YouTubePlayer
  }
};
</script>
<style>

</style>