import { createRouter, createWebHistory } from 'vue-router'
import Landing from '../views/Landing.vue';
import store from '../store'
import { BASE_URL } from '../config';

const routes = [
  {
    path: '/',
    name: 'home',
    component: Landing,
    meta: { 
      title: 'Monitoreo y Reporte Participativo', 
      description: 'MRP es un software que apoya a las empresas en el relacionamiento comunitario, estableciendo un canal de comunicación eficaz con los stakeholders',
      ogImage: `${BASE_URL}/img/logo.857e78fe.png`,
      ogType: 'website',
      ogUrl: `${BASE_URL}`
    }
  },
  {
    path: '/homeview',
    name: 'HomeView',

    component: () => import(/* webpackChunkName: "Landing" */ '../views/HomeView.vue'),
    meta: {rutaProtegida: true, role: 'vecino'}
  },
  {
    path: '/politica-privacidad',
    name: 'politica-privacidad',
  
    component: () => import(/* webpackChunkName: "Politica" */ '../views/Politica.vue'),
    meta: { title: 'Política de privacidad de MRP' }
    //meta: {rutaProtegida: true}
  },
  {
    path: '/blog',
    name: 'Blog',
  
    component: () => import(/* webpackChunkName: "Blog" */ '../views/Blog.vue'),
    meta: { 
      title: 'MRP: Blog', 
      description: 'MRP es un software que apoya a las empresas en el relacionamiento comunitario, estableciendo un canal de comunicación eficaz con los stakeholders',
      ogImage: `${BASE_URL}/img/logo.857e78fe.png`,
      ogType: 'website',
      ogUrl: `${BASE_URL}`
    }
  },
  {
    path: '/blog/comunicacion-poco-efectiva',
    name: 'comunicacion-poco-efectiva',
  
    component: () => import(/* webpackChunkName: "Post1" */ '../views/Post-1.vue'),
    meta: { title: 'MRP: Comunicación poco efectiva' }
    //meta: {rutaProtegida: true}
  },
  {
    path: '/blog/construyendo-relaciones-sostenibles',
    name: 'construyendo-relaciones-sostenibles',
  
    component: () => import(/* webpackChunkName: "Post2" */ '../views/Post-2.vue'),
    meta: { title: 'MRP: Relaciones sostenibles' }
    //meta: {rutaProtegida: true}
  },
  {
    path: '/blog/el-rol-de-la-tecnologia',
    name: 'el-rol-de-la-tecnologia',
  
    component: () => import(/* webpackChunkName: "Post3" */ '../views/Post-3.vue'),
    meta: { title: 'MRP: El rol de la tecnología' }
    //meta: {rutaProtegida: true}
  },
  {
    path: '/blog/transparencia-y-rendicion-de-cuentas',
    name: 'transparencia-y-rendicion-de-cuentas',
  
    component: () => import(/* webpackChunkName: "Post4" */ '../views/Post-4.vue'),
    meta: { title: 'MRP: Transparencia y rendición' }
    //meta: {rutaProtegida: true}
  },
  {
    path: '/editar/:id',
    name: 'Editar',
  
    component: () => import(/* webpackChunkName: "edir" */ '../views/Editar.vue'),
    meta: {rutaProtegida: true}
  },
  {
    path: '/registro',
    name: 'Registro',

    component: () => import(/* webpackChunkName: "about" */ '../views/Registro.vue')
  },
  {
    path: '/ingreso',
    name: 'Ingreso',

    component: () => import(/* webpackChunkName: "Ingreso" */ '../views/Ingreso.vue')
  },
  {
    path: '/test',
    name: 'Test',

    component: () => import(/* webpackChunkName: "Test" */ '../views/Test.vue')
  },
  {
    path: '/usuario',
    name: 'Usuario',
    
    component: () => import(/* webpackChunkName: "Usuario" */ '../views/Usuario.vue'),
    meta: {rutaProtegida: true, role: 'vecino'}
  },
  {
    path: '/reclamos',
    name: 'Reclamos',
    
    component: () => import(/* webpackChunkName: "Reclamos" */ '../views/Reclamos.vue'),
    meta: {rutaProtegida: true},
    meta: {tipoAdministrador: true, role: 'administrador'}
  },
  {
    path: '/empresa',
    name: 'Empresa',
    
    component: () => import(/* webpackChunkName: "Empresa" */ '../views/Empresa.vue'),
    meta: {rutaProtegida: true, role: 'empresa'}
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    
    component: () => import(/* webpackChunkName: "Dashboard" */ '../views/Dashboard.vue'),
    meta: {rutaProtegida: true, role: 'empresa'},
  },
  {
    path: '/dashboardPBI',
    name: 'DashboardPBI',
    
    component: () => import(/* webpackChunkName: "Dashboard" */ '../views/DashboardPBI.vue'),
    meta: {rutaProtegida: true, role: 'empresa'},
  },
  {
    path: '/modal',
    name: 'Modal',
    
    component: () => import(/* webpackChunkName: "Modal" */ '../views/Modal.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.rutaProtegida) {
    if (store.getters.userAutenticado){
      next()
    } else {
      next('/ingreso')
    }
  }else {
    next()
  }
   // Cambiar el título de la página usando meta tags
  document.title = to.meta.title 

  const description = to.meta.description || 'Default description';

  updateMetaTag('description', description);

  updateMetaTag('og:title', to.meta.title || 'Default Title', 'property');
  updateMetaTag('og:description', description, 'property');
  updateMetaTag('og:image', to.meta.ogImage || `${BASE_URL}/default-image.jpg`, 'property');
  updateMetaTag('og:type', to.meta.ogType || 'website', 'property');
  updateMetaTag('og:url', to.meta.ogUrl || window.location.href, 'property');

  next();
});

//router.afterEach((to) => {
//  if (to.meta && to.meta.title) {
//    document.title = to.meta.title;
//  }
// });

// Función para actualizar o crear una meta tag
function updateMetaTag(name, content, attribute = 'name') {
  let metaTag = document.querySelector(`meta[${attribute}="${name}"]`);

  if (metaTag) {
    metaTag.setAttribute('content', content);
  } else {
    metaTag = document.createElement('meta');
    metaTag.setAttribute(attribute, name);
    metaTag.setAttribute('content', content);
    document.head.appendChild(metaTag);
  }
}
export default router 
