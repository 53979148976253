<template>
    <nav class="navbar fixed-top navbar-expand-lg navbar-dark bg-dark">
        <div class="container-fluid">
            <a class="navbar-brand" href="/">
                <img src="../assets/logo.png" alt="MRP" width="152" height="50">
            </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                    <li>
                        <a href="/" class="btn btn-dark" v-if="!userAutenticado" >
                        Inicio </a>
                    </li>
                    <li>
                        <a href="/#hacemos" class="btn btn-dark" v-if="!userAutenticado" >
                        Qué hacemos </a>
                    </li>
                    <li>
                        <a href="/#somos" class="btn btn-dark" v-if="!userAutenticado" >
                        Quienes somos </a>
                    </li>
                    <li>
                        <a href="/blog" class="btn btn-dark" v-if="!userAutenticado" >
                        Blog </a>
                    </li>
                    <li class="nav-item" v-if="userRole === 'vecino'">
                        <router-link 
                            class="btn btn-dark" 
                            to="/homeview"
                            v-if="userAutenticado"
                        >
                        Mis mensajes
                        </router-link>
                    </li>
                    <li class="nav-item" v-if="userRole === 'empresa' || userRole === 'admin'">
                        <router-link 
                            class="btn btn-dark" 
                            to="/dashboardPBI"
                            v-if="userAutenticado"
                        >
                        Dashboard
                        </router-link>
                    </li>
                    <li class="nav-item" v-if="userRole === 'admin'">
                        <router-link 
                            class="btn btn-dark" 
                            to="/reclamos"
                            v-if="userAutenticado"
                        >
                        Administrador
                        </router-link>
                    </li>
                    <li class="nav-item" v-if="userRole === 'empresa'">
                        <router-link 
                            class="btn btn-dark" 
                            to="/empresa"
                            v-if="userAutenticado"
                        >
                        Empresa
                        </router-link>
                    </li>
                </ul>
                    <router-link 
                        class="btn btn-dark" 
                        to="/ingreso"
                        v-if="!userAutenticado"
                    >
                    Ingresar
                    </router-link>
                    <router-link 
                        class="btn btn-dark" 
                        to="/registro"
                        v-if="!userAutenticado"
                    >
                    <!-- Registrar-->
                    </router-link>
                    <button
                        v-if="userAutenticado"
                        class="btn btn-dark" 
                        @click="cerrarSesion"
                    >
                    Cerrar Sesión -> ({{ user.email }})
                    </button>
                
            </div>
        </div>
    </nav>
</template>

<script>
import { mapActions, mapGetters,mapState } from 'vuex'

export default {
    computed:{
        ...mapGetters(['userAutenticado']),
        ...mapState(['user', 'usuario']),
        userRole() {
            //console.log(this.usuario.tipo)k
            return this.usuario.tipo
        }

    },
    methods:{
        ...mapActions(['cerrarSesion','cargarReclamos2', 'cargarUsuario']),
    },
    created(){
      this.cargarReclamos2()
      this.cargarUsuario()
    }
    }
</script>
